body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.ant-pro-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.ant-pro-layout-content {
  flex: 1;
  overflow: auto;
}

.ant-page-header-no-children{
  display: none;
}

.ant-table-cell{
  word-break: auto-phrase;
}
